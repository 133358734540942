@import url(https://fonts.cdnfonts.com/css/montserrat);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Montserrat', sans-serif;
}

.blinking-cursor {
  font-weight: 100;
  font-size: 30px;
  color: #2E3D48;
  -webkit-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
  overflow-y: scroll;

}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-webkit-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

textarea {
  width: 100%;
  height: 160px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}

.mintMore{
  color: rgb(109, 186, 248);
  text-decoration: underline;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  background-color: #31d40000;
  font-size: 25px;
  cursor: pointer;
}

@media screen and (max-width: 499px) {
  input[type=radio] {
    border: 0px;
    width: 90px;
    height: 20px;
  }
  .connect2-2{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
    margin-top: 2%;
   }

  .upload{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
  }

  .form {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  #block_container{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .main {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    padding-bottom: 2%;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1000;
    bottom: 0;
    background-color: black;
  }

  .form__input {
    font-size: 25px;
    width: 30%;
    border: none;
    border-radius: 10px;
    padding: 10px;
  }


  .comment {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    border-radius: 10px;
    padding: 5px;
  }

  .button {
    background-color: rgb(53, 173, 53);
    border-radius: 10px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    cursor: pointer;
    margin-top: 10px;
   }

  .button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .userPic {
    width: 45px;
    height: 45px;
    padding-right: 10px;
  }

  .user {
    display: flex;
    flex-flow: row nowrap;
  }

  .line {
    width: 100%;
    height: 5px;
    background-color: rgb(255, 196, 0);
    margin-bottom: 2%;
  }

  .tab1 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: black;

  }

  .centerMM {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;    
  }

  .tab1:hover {
    background-color: rgba(151, 151, 151, 0.637);

  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 200px;
    border:none;
    box-shadow: 0px 5px rgba(104, 75, 37, 0.938);
    border-radius: 10px;
    background-color: rgba(167, 121, 61, 0.938);
    color: white;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;    font-weight: 500;
    margin-bottom: 10%;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2Dis {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 200px;
    border:none;
    box-shadow: 0px 5px rgba(151, 124, 88, 0.938);
    border-radius: 10px;
    background-color: rgba(165, 134, 93, 0.938);
    color: rgb(173, 173, 173);
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;    font-weight: 500;
    margin-bottom: 10%;
    cursor: no-drop;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mM {
    width: 250px;
    height: 41px;
    cursor: pointer;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.055) 0%, rgba(0,0,0,0.366469268371411) 63%, rgba(0,0,0,0.7782339742537641) 89%), url(/static/media/bg.4c1d4245.jpg) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
   }

  .allwrap{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .clientZone {
    flex-flow: row wrap;
    margin-left: 2%;
    padding-top: 2%;

  }

  .clientZoneMain {
    display: flex;
    margin-bottom: 5%;
    padding-top: 2%;
    justify-content: space-between;
    width: 600px;

  }

  .nftPic {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .clientStory {
    font-size: 12px;
    color: black;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 400px;
    margin-left: 2%;
    margin-right: 3%;
    border-radius: 10px;
    text-align: justify;

  }

  .nftImg {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .picAndStory {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 1%;
  }

  .writer {
    width: 30px;
    height: 30px;
  }

  .picAndName {
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 187, 0);
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100px;
    border-radius: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .writerDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5PX;
  }

  .clientName {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    color: white;

  }

  .clientName2 {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 60px;
    color: white;

  }

  .ownersSection {
    display: flex;
    flex-flow: row wrap;
    overflow-x: scroll;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .voteArea {
    margin-top: 10px;
  }

  .rabitIcon {
    width: 80px;
    height: 80px;
    cursor: pointer
  }

  .rabitIcon:active {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .rabitIconDiv {

    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
  }

  .submitted {

    text-align: center;
    font-size: large;
    color: white;

  }

  .rabitIconDiv button {


    background-color: rgba(240, 248, 255, 0);

  }

  .rabitIconDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .score {
    font-size: 60px;
  }

  .voteBtn {
    font-size: 25px;
    width: 50px;
    background-color: white;
    cursor: pointer;
    color: black;
    border-radius: 5px;
  }

  .voteBtn:hover {
    background-color: rgb(192, 192, 192);
    color: #5c5c5c;
  }

  .voteBtn:active {
    background-color: #363636;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    color: rgb(255, 255, 255);
  }

  .points {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 25px;
    background-color: #3b3b3b80;
  }

  .countWords {
    color: white;
  }

  .logo {
    width: 250px;
    height: 147px;
  }

  .logoDiv {

    margin-top: 10px;
  }

  .nb {
    color: white;
    font-size: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: 500;
    font-style: italic;
    text-shadow: 4px 3px 0 #41414198;
    text-align: center;
    text-align: center;
  }
  
  .headerPicAndTitle {
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .closeNote {
    position: absolute;
    right: 30%;
    top: 13%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 35px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
    cursor: pointer;
  }

  .checkedPicDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .checkedPic {
    width: 170px;
    height: 170px;
  }

  .loginFormNote {
    height: 300px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .noteSuccess {
    color: white;
    text-align: center;
    margin-top: 15%;
    font-size: 25px;
  }

  .noteSuccess2 {
    font-size: 15px;
    color: white;
    text-align: center;
  }

    .voteBtn {
    color: white;
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn:hover {
    color: rgba(0, 0, 0, 0.562);
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn2 {
    color: rgba(255, 255, 255, 0.562);
    font-size: 20px;
    text-align: center;

  }

  .voteBtn2:hover {
    color: rgba(179, 179, 179, 0.562);
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }

  .continue {
    background-color: rgba(53, 173, 53, 0);
    border-radius: 7px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .continue:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
  }

  a {
    color: white;
    text-decoration: none;
  }


/* width */
::-webkit-scrollbar {
  width: 10px;
 }

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(88, 87, 82);
  border-radius: 50px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 105, 103);
  border-radius: 50px;
}

.picAndCon{
  display: flex;
  justify-content: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  flex-flow: column nowrap;
}

.imageBar img{
  width: 200px;
  margin-bottom: 4%;
  border: rgb(255, 255, 255) 2px solid;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.imageBar{
  margin-top: auto;
  margin-bottom: auto;
 
  }

.contentH{
  color: white;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  text-decoration: underline;
}

.contentBar p{
  color: black;
  font-weight: 600;
  text-align: center;
}

.contentBar{
  margin-top: 10%;

}

.textField{
  color: black;
  font-weight: 600;
}

.textField2{
  color: black;
  font-weight: 600;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 3%;
}

.description{
  color: white;
  font-weight: 500;
  font-size: 23px;
  margin-bottom: 1%;
  padding-left: 2%;
}

.descriptionCon{
  background-color: #5b5434;
  color: white;
  font-weight: 500;
  padding: 10px;
  width: 100%;
  font-size: 15px;
  border-radius: 10px;
  margin-bottom: 2%;
  border: 2px black dashed;
}

.descriptionMain{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
 }

.agreeBtn {
  display: flex;
  flex-flow: row nowrap;  
  margin-top: 7px;
  margin-bottom: 7px;
}

.agreeBtn4 {
  display: flex;
  flex-flow: row nowrap;  
  width: 100%;
}

.tic {
  width: 90px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 3%;
}

.agree {
  font-size: 15px;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
}

.agreeBtn2{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
}

.agreeBtn3{
  width: 100%;
  justify-content: flex-end;
  flex-flow: column nowrap;
  display: flex;
  margin-left: 5%;
  margin-top: 5%;
 }

.con2{
  color: white;
  font-weight: 500;
  margin-top: 2%;
  font-size: 15px;
  width: 90%;
  text-align: justify;
  margin-bottom: 5%;
}

.con2Main{
  justify-content: center;
  flex-flow: column nowrap;
  display: flex;
  width: 100%;
  margin-top: 2%;
}

.approve{
  background-color: rgb(1, 155, 53);
  color: white;
  font-weight: 600;
  border-radius: 15px;
  border: none;
  margin-top: 5%;
  justify-content: center;
  display: flex;
  padding: 7px;
  font-size: 18px;
  box-shadow: 0 5px rgb(69, 143, 94);
   cursor: no-drop;
  transition: ease-in 0.2px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.approveDis{
  background-color: rgb(97, 179, 124);
  color: rgb(177, 177, 177);
  font-weight: 600;
  border-radius: 15px;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  padding: 7px;
  font-size: 18px;
  box-shadow: 0 5px rgb(69, 143, 94);
   cursor: no-drop;
  transition: ease-in 0.2px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}


.approve:hover{
  background-color: rgb(2, 175, 59);
}

.approveDiv{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.wallet2:hover {
  -webkit-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
          animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

.choosebtn{
  color: white;
  font-size: 16px;
  }

  .ownNFT{
    display: flex;
    justify-content: space-between;
    margin-left: 2%;
    margin-top: 8%;
    margin-bottom: 40%;
  }


.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  display: none;

}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.formMain {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 80%;
  height: 70vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #32d400;
  z-index: 100000;
  padding: 5%;

}

.formMain2 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 80%;
  height: 35vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #32d400;
  z-index: 100000;
  padding: 5%;

}

.formMain1 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #131313b0;
  position: fixed;
  width: 100%; 
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

}

.closeNote2 {
  position: fixed;
  right: 0;
  top: 5%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
 }

.closeNote2:hover,
.closeNote2:focus {
  color: rgb(71, 70, 70);
}

.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  display: none;

}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.formCon{
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
}

.wallet2New{
  background-color: #5b5434;
  color: white;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 25px;
  margin-bottom: 2%;
  padding: 5px;
  border: white 2px solid;
  cursor: pointer;
}

.tokenIds {
  display: flex;
  justify-content: center;
  color: white;
  flex-flow: row wrap;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 10%;
 }

.images_show {
  width: 80%;

}

.flip-card {
  background-color: transparent;
  width: 150px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  background-color: #e0706c;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  margin-top: 10%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.btnCenter {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.flip-card-front {
  /* background-color: #bbb;*/
  color: black;
}

.flip-card-back {
  /*background-color: #2980b9;*/
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.contentColumn{
  flex-flow: column nowrap;
   
}

.formCon2{
  color: rgb(209, 209, 209);
  font-size: 14px;
  text-align: center;
}

.mintBtn{
  background-color: green;
  font-size: 20px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  padding: 10px;
  border: white solid 2px;
  margin-top: 5%;
  cursor: pointer;
}

.errorMessage {

  font-size: 15px;
  color: #fc534d;
  text-align: center;
  margin-top: 8%;

}

.loadingContainer {}

.loadingText {
  font-size: 18px;
  text-align: center;
  color: rgb(204, 204, 204);
  margin-top: 8%;
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.successfully {
  text-align: center;
  color: rgb(124, 199, 74);
  font-size: 18px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;

}

.arrow{
  width: 20px;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-left: 4%;
  cursor: pointer;
  -webkit-animation: zoominoutsinglefeatured 5s infinite;
          animation: zoominoutsinglefeatured 5s infinite;
}

@-webkit-keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

.arrowAndCon{
  display: flex;
  margin-top: 8%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

}

.instructions{
  color: white;
  text-align: center;
  margin-top: 10px;
  font-size: 17px;
}

.formCon2{
  color: rgb(212, 211, 211);
  text-align: center;
  margin-top: 10px;
}
}

@media screen and (min-width: 500px) and (max-width: 767px) {
 

  .connect2-2{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
    margin-top: 2%;
   }

  .upload{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
  }

  .form {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  #block_container{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .main {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    padding-bottom: 2%;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1000;
    bottom: 0;
    background-color: black;
  }

  .form__input {
    font-size: 25px;
    width: 30%;
    border: none;
    border-radius: 10px;
    padding: 10px;
  }

  .comment {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    border-radius: 10px;
    padding: 5px;
  }

  .button {
    background-color: rgb(53, 173, 53);
    border-radius: 10px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    cursor: pointer;
    margin-top: 10px;
   }

  .button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .userPic {
    width: 45px;
    height: 45px;
    padding-right: 10px;
  }

  .user {
    display: flex;
    flex-flow: row nowrap;
  }

  .line {
    width: 100%;
    height: 5px;
    background-color: rgb(255, 196, 0);
    margin-bottom: 2%;
  }

  .tab1 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: black;

  }

  .centerMM {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;    
  }

  .tab1:hover {
    background-color: rgba(151, 151, 151, 0.637);

  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 200px;
    border:none;
    box-shadow: 0px 5px rgba(104, 75, 37, 0.938);
    border-radius: 10px;
    background-color: rgba(167, 121, 61, 0.938);
    color: white;
    font-size: 20px;
    justify-content: flex-end;
    font-weight: 500;
    margin-bottom: 10%;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2Dis {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 200px;
    border:none;
    box-shadow: 0px 5px rgba(151, 124, 88, 0.938);
    border-radius: 10px;
    background-color: rgba(165, 134, 93, 0.938);
    color: rgb(173, 173, 173);
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;    font-weight: 500;
    margin-bottom: 10%;
    cursor: no-drop;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mM {
    width: 250px;
    height: 41px;
    cursor: pointer;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.055) 0%, rgba(0,0,0,0.366469268371411) 63%, rgba(0,0,0,0.7782339742537641) 89%), url(/static/media/bg.4c1d4245.jpg) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
   }

  .allwrap{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .clientZone {
    flex-flow: row wrap;
    margin-left: 2%;
    padding-top: 2%;

  }

  .clientZoneMain {
    display: flex;
    margin-bottom: 5%;
    padding-top: 2%;
    justify-content: space-between;
    width: 600px;

  }

  .nftPic {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .clientStory {
    font-size: 12px;
    color: black;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 400px;
    margin-left: 2%;
    margin-right: 3%;
    border-radius: 10px;
    text-align: justify;

  }

  .nftImg {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .picAndStory {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 1%;
  }

  .writer {
    width: 30px;
    height: 30px;
  }

  .picAndName {
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 187, 0);
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100px;
    border-radius: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .writerDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5PX;
  }

  .clientName {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    color: white;

  }

  .clientName2 {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 60px;
    color: white;

  }

  .ownersSection {
    display: flex;
    flex-flow: row wrap;
    overflow-x: scroll;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .voteArea {
    margin-top: 10px;
  }

  .rabitIcon {
    width: 80px;
    height: 80px;
    cursor: pointer
  }

  .rabitIcon:active {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .rabitIconDiv {

    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
  }

  .submitted {

    text-align: center;
    font-size: large;
    color: white;

  }

  .rabitIconDiv button {


    background-color: rgba(240, 248, 255, 0);

  }

  .rabitIconDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .score {
    font-size: 60px;
  }

  .voteBtn {
    font-size: 25px;
    width: 50px;
    background-color: white;
    cursor: pointer;
    color: black;
    border-radius: 5px;
  }

  .voteBtn:hover {
    background-color: rgb(192, 192, 192);
    color: #5c5c5c;
  }

  .voteBtn:active {
    background-color: #363636;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    color: rgb(255, 255, 255);
  }

  .points {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 25px;
    background-color: #3b3b3b80;
  }

  .countWords {
    color: white;
  }

  .logo {
    width: 250px;
    height: 147px;
  }

  .logoDiv {

    margin-top: 10px;
  }

  .nb {
    color: white;
    font-size: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: 500;
    font-style: italic;
    text-shadow: 4px 3px 0 #41414198;
    text-align: center;
    text-align: center;
  }
  
  .headerPicAndTitle {
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .closeNote {
    position: absolute;
    right: 30%;
    top: 13%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 35px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
    cursor: pointer;
  }

  .checkedPicDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .checkedPic {
    width: 170px;
    height: 170px;
  }

  .loginFormNote {
    height: 300px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .noteSuccess {
    color: white;
    text-align: center;
    margin-top: 15%;
    font-size: 25px;
  }

  .noteSuccess2 {
    font-size: 15px;
    color: white;
    text-align: center;
  }

    .voteBtn {
    color: white;
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn:hover {
    color: rgba(0, 0, 0, 0.562);
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn2 {
    color: rgba(255, 255, 255, 0.562);
    font-size: 20px;
    text-align: center;

  }

  .voteBtn2:hover {
    color: rgba(179, 179, 179, 0.562);
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }

  .continue {
    background-color: rgba(53, 173, 53, 0);
    border-radius: 7px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .continue:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
  }

  a {
    color: white;
    text-decoration: none;
  }


/* width */
::-webkit-scrollbar {
  width: 10px;
 }

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(88, 87, 82);
  border-radius: 50px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 105, 103);
  border-radius: 50px;
}

.picAndCon{
  display: flex;
  justify-content: center;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  flex-flow: column nowrap;
}

.imageBar img{
  width: 200px;
  margin-bottom: 4%;
  border: rgb(255, 255, 255) 2px solid;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.imageBar{
  margin-top: auto;
  margin-bottom: auto;
 
  }

.contentH{
  color: white;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  text-decoration: underline;
}

.contentBar p{
  color: black;
  font-weight: 600;
  text-align: center;
}

.contentBar{
  margin-top: 10%;

}

.textField{
  color: black;
  font-weight: 600;
}

.textField2{
  color: black;
  font-weight: 600;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 3%;
}

.description{
  color: white;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 1%;
  padding-left: 2%;
}

.descriptionCon{
  background-color: #5b5434;
  color: white;
  font-weight: 500;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  border-radius: 10px;
  margin-bottom: 2%;
  border: 2px black dashed;
}

.descriptionMain{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
 }

.agreeBtn {
  display: flex;
  flex-flow: row nowrap;  
}

.agreeBtn4 {
  display: flex;
  flex-flow: row nowrap;  
  width: 100%;
}

.tic {
  width: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 3%;
}

.agree {
  font-size: 17px;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
}

.agreeBtn2{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
}

.agreeBtn3{
  width: 100%;
  justify-content: flex-end;
  flex-flow: column nowrap;
  display: flex;
  margin-left: 5%;
  margin-top: 5%;
}

.con2{
  color: white;
  font-weight: 500;
  margin-top: 2%;
  font-size: 18px;
  width: 90%;
  text-align: justify;
  margin-bottom: 5%;
}

.con2Main{
  justify-content: center;
  flex-flow: column nowrap;
  display: flex;
  width: 100%;
  margin-top: 2%;
}

.approve{
  background-color: rgb(1, 155, 53);
  color: white;
  font-weight: 600;
  border-radius: 15px;
  border: none;
  margin-top: 5%;
  justify-content: center;
  display: flex;
  padding: 10px;
  font-size: 20px;
  box-shadow: 0 5px rgb(1, 119, 40);
  cursor: pointer;
  transition: ease-in 0.2px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.approveDis{
  background-color: rgb(97, 179, 124);
  color: rgb(177, 177, 177);
  font-weight: 600;
  border-radius: 15px;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  padding: 10px;
  font-size: 20px;
  box-shadow: 0 5px rgb(69, 143, 94);
  margin-right: 5%;
  cursor: no-drop;
  transition: ease-in 0.2px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}


.approve:hover{
  background-color: rgb(2, 175, 59);
}

.approveDiv{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.wallet2:hover {
  -webkit-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
          animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

.choosebtn{
  color: white;
  font-size: 16px;
  }

  .ownNFT{
    display: flex;
    justify-content: space-between;
    margin-left: 2%;
    margin-top: 8%;
    margin-bottom: 33%;
  }


.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  display: none;

}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.formMain {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 80%;
  height: 70vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #32d400;
  z-index: 100000;
  padding: 5%;

}

.formMain2 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 70%;
  height: 40vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #32d400;
  z-index: 100000;
  padding: 5%;

}

.formMain1 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #131313b0;
  position: fixed;
  width: 100%; 
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

}

.closeNote2 {
  position: fixed;
  right: 0;
  top: 5%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
 }

.closeNote2:hover,
.closeNote2:focus {
  color: rgb(71, 70, 70);
}

.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  display: none;

}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.formCon{
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
}

.wallet2New{
  background-color: #5b5434;
  color: white;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 25px;
  margin-bottom: 2%;
  padding: 5px;
  border: white 2px solid;
  cursor: pointer;
}

.tokenIds {
  display: flex;
  justify-content: center;
  color: white;
  flex-flow: row wrap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 10%;
}

.images_show {
  width: 90%;

}

.flip-card {
  background-color: transparent;
  width: 150px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  background-color: #e0706c;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  margin-top: 10%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.btnCenter {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.flip-card-front {
  /* background-color: #bbb;*/
  color: black;
}

.flip-card-back {
  /*background-color: #2980b9;*/
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.contentColumn{
  flex-flow: column nowrap;
   
}

.formCon2{
  color: rgb(209, 209, 209);
  font-size: 16px;
  text-align: center;
}

.mintBtn{
  background-color: green;
  font-size: 20px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  padding: 10px;
  border: white solid 2px;
  margin-top: 5%;
  cursor: pointer;
}

.errorMessage {

  font-size: 18px;
  color: #fc534d;
  text-align: center;
  margin-top: 8%;

}

.loadingContainer {}

.loadingText {
  font-size: 22px;
  text-align: center;
  color: rgb(204, 204, 204);
  margin-top: 8%;
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  margin-top: 8%;
}

/* loading dots */


.loadingText:after {
  content: '.';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.successfully {
  text-align: center;
  color: rgb(124, 199, 74);
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.arrow{
  width: 25px;
  height: 25px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-left: 4%;
  cursor: pointer;
  -webkit-animation: zoominoutsinglefeatured 5s infinite;
          animation: zoominoutsinglefeatured 5s infinite;
}

@-webkit-keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

.arrowAndCon{
  display: flex;
  margin-top: 8%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

}

.instructions{
  color: white;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

.formCon2{
  color: rgb(212, 211, 211);
  text-align: center;
  margin-top: 10px;
}
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .connect2-2{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
    margin-top: 2%;
   }

  .upload{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .form {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  #block_container{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .main {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    padding-bottom: 2%;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1000;
    bottom: 0;
    background-color: black;
  }

  .form__input {
    font-size: 25px;
    width: 30%;
    border: none;
    border-radius: 10px;
    padding: 10px;
  }

  .comment {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    border-radius: 10px;
    padding: 5px;
  }

  .button {
    background-color: rgb(53, 173, 53);
    border-radius: 10px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    cursor: pointer;
    margin-top: 10px;
   }

  .button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .userPic {
    width: 45px;
    height: 45px;
    padding-right: 10px;
  }

  .user {
    display: flex;
    flex-flow: row nowrap;
  }

  .line {
    width: 100%;
    height: 5px;
    background-color: rgb(255, 196, 0);
    margin-bottom: 2%;
  }

  .tab1 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: black;

  }

  .centerMM {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;    
  }

  .tab1:hover {
    background-color: rgba(151, 151, 151, 0.637);

  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 200px;
    border:none;
    box-shadow: 0px 5px rgba(104, 75, 37, 0.938);
    border-radius: 10px;
    background-color: rgba(167, 121, 61, 0.938);
    color: white;
    font-size: 20px;
    justify-content: flex-end;
    font-weight: 500;
    margin-bottom: 10%;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2Dis {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 200px;
    border:none;
    box-shadow: 0px 5px rgba(151, 124, 88, 0.938);
    border-radius: 10px;
    background-color: rgba(165, 134, 93, 0.938);
    color: rgb(173, 173, 173);
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;    font-weight: 500;
    margin-bottom: 10%;
    cursor: no-drop;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mM {
    width: 250px;
    height: 41px;
    cursor: pointer;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.055) 0%, rgba(0,0,0,0.366469268371411) 63%, rgba(0,0,0,0.7782339742537641) 89%), url(/static/media/bg.4c1d4245.jpg) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
   }

  .allwrap{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .clientZone {
    flex-flow: row wrap;
    margin-left: 2%;
    padding-top: 2%;

  }

  .clientZoneMain {
    display: flex;
    margin-bottom: 5%;
    padding-top: 2%;
    justify-content: space-between;
    width: 600px;

  }

  .nftPic {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .clientStory {
    font-size: 12px;
    color: black;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 400px;
    margin-left: 2%;
    margin-right: 3%;
    border-radius: 10px;
    text-align: justify;

  }

  .nftImg {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .picAndStory {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 1%;
  }

  .writer {
    width: 30px;
    height: 30px;
  }

  .picAndName {
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 187, 0);
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100px;
    border-radius: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .writerDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5PX;
  }

  .clientName {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    color: white;

  }

  .clientName2 {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 60px;
    color: white;

  }

  .ownersSection {
    display: flex;
    flex-flow: row wrap;
    overflow-x: scroll;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .voteArea {
    margin-top: 10px;
  }

  .rabitIcon {
    width: 80px;
    height: 80px;
    cursor: pointer
  }

  .rabitIcon:active {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .rabitIconDiv {

    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
  }

  .submitted {

    text-align: center;
    font-size: large;
    color: white;

  }

  .rabitIconDiv button {


    background-color: rgba(240, 248, 255, 0);

  }

  .rabitIconDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .score {
    font-size: 60px;
  }

  .voteBtn {
    font-size: 25px;
    width: 50px;
    background-color: white;
    cursor: pointer;
    color: black;
    border-radius: 5px;
  }

  .voteBtn:hover {
    background-color: rgb(192, 192, 192);
    color: #5c5c5c;
  }

  .voteBtn:active {
    background-color: #363636;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    color: rgb(255, 255, 255);
  }

  .points {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 25px;
    background-color: #3b3b3b80;
  }

  .countWords {
    color: white;
  }

  .logo {
    width: 250px;
    height: 147px;
  }

  .logoDiv {

    margin-top: 10px;
  }

  .nb {
    color: white;
    font-size: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: 500;
    font-style: italic;
    text-shadow: 4px 3px 0 #41414198;
    text-align: center;
    text-align: center;
  }
  
  .headerPicAndTitle {
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .closeNote {
    position: absolute;
    right: 30%;
    top: 13%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 35px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
    cursor: pointer;
  }

  .checkedPicDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .checkedPic {
    width: 170px;
    height: 170px;
  }

  .loginFormNote {
    height: 300px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .noteSuccess {
    color: white;
    text-align: center;
    margin-top: 15%;
    font-size: 25px;
  }

  .noteSuccess2 {
    font-size: 15px;
    color: white;
    text-align: center;
  }

    .voteBtn {
    color: white;
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn:hover {
    color: rgba(0, 0, 0, 0.562);
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn2 {
    color: rgba(255, 255, 255, 0.562);
    font-size: 20px;
    text-align: center;

  }

  .voteBtn2:hover {
    color: rgba(179, 179, 179, 0.562);
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }

  .continue {
    background-color: rgba(53, 173, 53, 0);
    border-radius: 7px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .continue:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
  }

  a {
    color: white;
    text-decoration: none;
  }


/* width */
::-webkit-scrollbar {
  width: 10px;
 }

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(88, 87, 82);
  border-radius: 50px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 105, 103);
  border-radius: 50px;
}

.picAndCon{
  display: flex;
  justify-content: center;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  flex-flow: row nowrap;
}

.imageBar img{
  width: 200px;
  margin-bottom: 4%;
  border: rgb(255, 255, 255) 2px solid;
}

.imageBar{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.contentH{
  color: white;
  font-weight: 500;
  font-size: 30px;
  text-align: left;
  text-decoration: underline;
}

.contentBar p{
  color: black;
  font-weight: 600;
  text-align: left;
}

.textField{
  color: black;
  font-weight: 600;
}

.textField2{
  color: black;
  font-weight: 600;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 3%;
}

.description{
  color: white;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 1%;
  padding-left: 2%;
}

.descriptionCon{
  background-color: #5b5434;
  color: white;
  font-weight: 500;
  padding: 10px;
  width: 100%;
  font-size: 18px;
  border-radius: 10px;
  margin-bottom: 2%;
  border: 2px black dashed;
}

.descriptionMain{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.agreeBtn {
  display: flex;
  flex-flow: row nowrap;  
}

.agreeBtn4 {
  display: flex;
  flex-flow: row nowrap;  
  width: 100%;
}

.tic {
  width: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 3%;
}

.agree {
  font-size: 17px;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
}

.agreeBtn2{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.agreeBtn3{
  width: 100%;
  justify-content: flex-end;
  flex-flow: column nowrap;
  display: flex;
  margin-left: 2%;
}

.con2{
  color: white;
  font-weight: 500;
  margin-top: 2%;
  font-size: 18px;
  width: 70%;
  text-align: justify;
}

.con2Main{
  justify-content: space-between;
  flex-flow: row nowrap;
  display: flex;
  width: 100%;
  margin-top: 2%;
}

.approve{
  background-color: rgb(1, 155, 53);
  color: white;
  font-weight: 600;
  border-radius: 15px;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  padding: 10px;
  font-size: 20px;
  box-shadow: 0 5px rgb(1, 119, 40);
  margin-right: 5%;
  cursor: pointer;
  transition: ease-in 0.2px
}

.approveDis{
  background-color: rgb(97, 179, 124);
  color: rgb(177, 177, 177);
  font-weight: 600;
  border-radius: 15px;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  padding: 10px;
  font-size: 20px;
  box-shadow: 0 5px rgb(69, 143, 94);
  margin-right: 5%;
  cursor: no-drop;
  transition: ease-in 0.2px;
}


.approve:hover{
  background-color: rgb(2, 175, 59);
}

.approveDiv{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.wallet2:hover {
  -webkit-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
          animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

.choosebtn{
  color: white;
  font-size: 16px;
}

.ownNFT{
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
  margin-top: 8%;
  margin-bottom: 19%;
}

.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  display: none;

}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.formMain {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 80%;
  height: 70vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #32d400;
  z-index: 100000;
  padding: 5%;

}

.formMain2 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 50%;
  height: 35vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #32d400;
  z-index: 100000;
  padding: 5%;

}

.formMain1 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #131313b0;
  position: fixed;
  width: 100%; 
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

}

.closeNote2 {
  position: fixed;
  right: 0;
  top: 5%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
 }

.closeNote2:hover,
.closeNote2:focus {
  color: rgb(71, 70, 70);
}

.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  display: none;

}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.formCon{
  color: white;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
}

.wallet2New{
  background-color: #5b5434;
  color: white;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 25px;
  margin-bottom: 2%;
  padding: 5px;
  border: white 2px solid;
  cursor: pointer;
}

.tokenIds {
  display: flex;
  justify-content: center;
  color: white;
  flex-flow: row wrap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 10%;
}

.images_show {
  width: 90%;

}

.flip-card {
  background-color: transparent;
  width: 150px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  background-color: #e0706c;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  margin-top: 10%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.btnCenter {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.flip-card-front {
  /* background-color: #bbb;*/
  color: black;
}

.flip-card-back {
  /*background-color: #2980b9;*/
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.contentColumn{
  flex-flow: column nowrap;
   
}

.formCon2{
  color: rgb(209, 209, 209);
  font-size: 16px;
  text-align: center;
}

.mintBtn{
  background-color: green;
  font-size: 20px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  padding: 10px;
  border: white solid 2px;
  margin-top: 5%;
  cursor: pointer;
}

.errorMessage {

  font-size: 18px;
  color: #fc534d;
  text-align: center;
  margin-top: 3%;

}

.loadingContainer {}

.loadingText {
  font-size: 22px;
  text-align: center;
  color: rgb(204, 204, 204);
  margin-top: 3%;
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.successfully {
  text-align: center;
  color: rgb(124, 199, 74);
  font-size: 22px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.arrow{
  width: 30px;
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-left: 4%;
  cursor: pointer;
  -webkit-animation: zoominoutsinglefeatured 5s infinite;
          animation: zoominoutsinglefeatured 5s infinite;
}

@-webkit-keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

.arrowAndCon{
  display: flex;
  margin-top: 3%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

}

.instructions{
  color: white;
  text-align: center;
  margin-top: 10px;
}

.formCon2{
  color: rgb(212, 211, 211);
  text-align: center;
  margin-top: 10px;
}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .upload{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .form {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  #block_container{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .main {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    padding-bottom: 2%;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1000;
    bottom: 0;
    background-color: black;
  }

  .form__input {
    font-size: 25px;
    width: 30%;
    border: none;
    border-radius: 10px;
    padding: 10px;
  }

  .comment {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    border-radius: 10px;
    padding: 5px;
  }

  .button {
    background-color: rgb(53, 173, 53);
    border-radius: 10px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    cursor: pointer;
    margin-top: 10px;
   }

  .button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .userPic {
    width: 45px;
    height: 45px;
    padding-right: 10px;
  }

  .user {
    display: flex;
    flex-flow: row nowrap;
  }

  .line {
    width: 100%;
    height: 5px;
    background-color: rgb(255, 196, 0);
    margin-bottom: 2%;
  }

  .tab1 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: black;

  }

  .centerMM {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;    
  }

  .tab1:hover {
    background-color: rgba(151, 151, 151, 0.637);

  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 200px;
    border:none;
    box-shadow: 0px 5px rgba(104, 75, 37, 0.938);
    border-radius: 10px;
    background-color: rgba(167, 121, 61, 0.938);
    color: white;
    font-size: 20px;
    justify-content: flex-end;
    font-weight: 500;
    margin-bottom: 10%;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2Dis {

    padding-top: 5px;
    padding-bottom: 5px;
    width: 200px;
    border:none;
    box-shadow: 0px 5px rgba(151, 124, 88, 0.938);
    border-radius: 10px;
    background-color: rgba(165, 134, 93, 0.938);
    color: rgb(173, 173, 173);
    font-size: 20px;
     font-weight: 500;
    margin-bottom: 10%;
    cursor: no-drop;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mM {
    width: 250px;
    height: 41px;
    cursor: pointer;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.055) 0%, rgba(0,0,0,0.366469268371411) 63%, rgba(0,0,0,0.7782339742537641) 89%), url(/static/media/bg.4c1d4245.jpg) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
   }

  .allwrap{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .clientZone {
    flex-flow: row wrap;
    margin-left: 2%;
    padding-top: 2%;

  }

  .clientZoneMain {
    display: flex;
    margin-bottom: 5%;
    padding-top: 2%;
    justify-content: space-between;
    width: 600px;

  }

  .nftPic {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .clientStory {
    font-size: 12px;
    color: black;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 400px;
    margin-left: 2%;
    margin-right: 3%;
    border-radius: 10px;
    text-align: justify;

  }

  .nftImg {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .picAndStory {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 1%;
  }

  .writer {
    width: 30px;
    height: 30px;
  }

  .picAndName {
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 187, 0);
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100px;
    border-radius: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .writerDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5PX;
  }

  .clientName {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    color: white;

  }

  .clientName2 {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 60px;
    color: white;

  }

  .ownersSection {
    display: flex;
    flex-flow: row wrap;
    overflow-x: scroll;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .voteArea {
    margin-top: 10px;
  }

  .rabitIcon {
    width: 80px;
    height: 80px;
    cursor: pointer
  }

  .rabitIcon:active {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .rabitIconDiv {

    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
  }

  .submitted {

    text-align: center;
    font-size: large;
    color: white;

  }

  .rabitIconDiv button {


    background-color: rgba(240, 248, 255, 0);

  }

  .rabitIconDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .score {
    font-size: 60px;
  }

  .voteBtn {
    font-size: 25px;
    width: 50px;
    background-color: white;
    cursor: pointer;
    color: black;
    border-radius: 5px;
  }

  .voteBtn:hover {
    background-color: rgb(192, 192, 192);
    color: #5c5c5c;
  }

  .voteBtn:active {
    background-color: #363636;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    color: rgb(255, 255, 255);
  }

  .points {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 25px;
    background-color: #3b3b3b80;
  }

  .countWords {
    color: white;
  }

  .logo {
    width: 250px;
    height: 147px;
  }

  .logoDiv {

    margin-top: 10px;
  }

  .nb {
    color: white;
    font-size: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: 500;
    font-style: italic;
    text-shadow: 4px 3px 0 #41414198;
  }
  
  .headerPicAndTitle {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .closeNote {
    position: absolute;
    right: 30%;
    top: 13%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 35px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
    cursor: pointer;
  }

  .checkedPicDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .checkedPic {
    width: 170px;
    height: 170px;
  }

  .loginFormNote {
    height: 300px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .noteSuccess {
    color: white;
    text-align: center;
    margin-top: 15%;
    font-size: 25px;
  }

  .noteSuccess2 {
    font-size: 15px;
    color: white;
    text-align: center;
  }

    .voteBtn {
    color: white;
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn:hover {
    color: rgba(0, 0, 0, 0.562);
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn2 {
    color: rgba(255, 255, 255, 0.562);
    font-size: 20px;
    text-align: center;

  }

  .voteBtn2:hover {
    color: rgba(179, 179, 179, 0.562);
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }

  .continue {
    background-color: rgba(53, 173, 53, 0);
    border-radius: 7px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .continue:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
  }

  a {
    color: white;
    text-decoration: none;
  }


/* width */
::-webkit-scrollbar {
  width: 10px;
 }

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(88, 87, 82);
  border-radius: 50px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 105, 103);
  border-radius: 50px;
}

.picAndCon{
  display: flex;
  justify-content: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  flex-flow: row nowrap;
}

.imageBar img{
  width: 200px;
  margin-bottom: 4%;
  border: rgb(255, 255, 255) 2px solid;
}

.imageBar{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.contentH{
  color: white;
  font-weight: 500;
  font-size: 30px;
  text-align: left;
  text-decoration: underline;
}

.contentBar p{
  color: black;
  font-weight: 600;
  text-align: left;
}

.textField{
  color: black;
  font-weight: 600;
}

.textField2{
  color: black;
  font-weight: 600;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 3%;
}

.description{
  color: white;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 1%;
  padding-left: 2%;
}

.descriptionCon{
  background-color: #5b5434;
  color: white;
  font-weight: 500;
  padding: 10px;
  width: 100%;
  font-size: 18px;
  border-radius: 10px;
  margin-bottom: 2%;
  border: 2px black dashed;
}

.descriptionMain{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.agreeBtn {
  display: flex;
  flex-flow: row nowrap;  
}

.agreeBtn4 {
  display: flex;
  flex-flow: row nowrap;  
  width: 100%;
}

.tic {
  width: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 3%;
}

.agree {
  font-size: 17px;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
}

.agreeBtn2{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.agreeBtn3{
  width: 100%;
  justify-content: flex-end;
  flex-flow: column nowrap;
  display: flex;
  margin-left: 2%;
}

.con2{
  color: white;
  font-weight: 500;
  margin-top: 2%;
  font-size: 18px;
  width: 70%;
  text-align: justify;
}

.con2Main{
  justify-content: space-between;
  flex-flow: row nowrap;
  display: flex;
  width: 100%;
  margin-top: 2%;
}

.approve{
  background-color: rgb(1, 155, 53);
  color: white;
  font-weight: 600;
  border-radius: 15px;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  padding: 10px;
  font-size: 20px;
  box-shadow: 0 5px rgb(1, 119, 40);
  margin-right: 5%;
  cursor: pointer;
  transition: ease-in 0.2px
}

.approveDis{
  background-color: rgb(97, 179, 124);
  color: rgb(177, 177, 177);
  font-weight: 600;
  border-radius: 15px;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  padding: 10px;
  font-size: 20px;
  box-shadow: 0 5px rgb(69, 143, 94);
  margin-right: 5%;
  cursor: no-drop;
  transition: ease-in 0.2px;
}


.approve:hover{
  background-color: rgb(2, 175, 59);
}

.approveDiv{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.wallet2:hover {
  -webkit-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
          animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

.choosebtn{
  color: white;
  font-size: 16px;
}

.ownNFT{
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
  margin-top: 8%;
  margin-bottom: 13%;
}


.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  display: none;

}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.formMain {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 80%;
  height: 70vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #32d400;
  z-index: 100000;
  padding: 5%;

}

.formMain2 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 50%;
  height: 35vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #32d400;
  z-index: 100000;
  padding: 5%;

}

.formMain1 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #131313b0;
  position: fixed;
  width: 100%; 
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

}

.closeNote2 {
  position: fixed;
  right: 0;
  top: 5%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
 }

.closeNote2:hover,
.closeNote2:focus {
  color: rgb(71, 70, 70);
}

.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  display: none;

}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.formCon{
  color: white;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
}

.wallet2New{
  background-color: #5b5434;
  color: white;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 25px;
  margin-bottom: 2%;
  padding: 5px;
  border: white 2px solid;
  cursor: pointer;
}

.tokenIds {
  display: flex;
  justify-content: center;
  color: white;
  flex-flow: row wrap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 10%;
}

.images_show {
  width: 90%;

}



.flip-card {
  background-color: transparent;
  width: 150px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  background-color: #e0706c;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  margin-top: 10%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.btnCenter {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.flip-card-front {
  /* background-color: #bbb;*/
  color: black;
}

.flip-card-back {
  /*background-color: #2980b9;*/
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.contentColumn{
  flex-flow: column nowrap;
   
}

.formCon2{
  color: rgb(209, 209, 209);
  font-size: 16px;
  text-align: center;
}

.mintBtn{
  background-color: green;
  font-size: 20px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  padding: 10px;
  border: white solid 2px;
  margin-top: 5%;
  cursor: pointer;
}

.errorMessage {

  font-size: 18px;
  color: #fc534d;
  text-align: center;
  margin-top: 3%;

}

.loadingContainer {}

.loadingText {
  font-size: 22px;
  text-align: center;
  color: rgb(204, 204, 204);
  margin-top: 3%;
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.successfully {
  text-align: center;
  color: rgb(124, 199, 74);
  font-size: 22px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.arrow{
  width: 30px;
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-left: 4%;
  cursor: pointer;
  -webkit-animation: zoominoutsinglefeatured 5s infinite;
          animation: zoominoutsinglefeatured 5s infinite;
}

@-webkit-keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

.arrowAndCon{
  display: flex;
  margin-top: 3%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

}

.instructions{
  color: white;
  text-align: center;
  margin-top: 10px;
}

.formCon2{
  color: rgb(212, 211, 211);
  text-align: center;
  margin-top: 10px;
}
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .connect2{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
     display: flex;
    }

  .upload{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .form {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  #block_container{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .main {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    padding-bottom: 2%;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1000;
    bottom: 0;
    background-color: black;
  }

  .form__input {
    font-size: 25px;
    width: 30%;
    border: none;
    border-radius: 10px;
    padding: 10px;
  }

  .comment {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    border-radius: 10px;
    padding: 5px;
  }

  .button {
    background-color: rgb(53, 173, 53);
    border-radius: 10px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    cursor: pointer;
    margin-top: 10px;
   }

  .button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .userPic {
    width: 45px;
    height: 45px;
    padding-right: 10px;
  }

  .user {
    display: flex;
    flex-flow: row nowrap;
  }

  .line {
    width: 100%;
    height: 5px;
    background-color: rgb(255, 196, 0);
    margin-bottom: 2%;
  }

  .tab1 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: black;

  }

  .centerMM {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;    
  }

  .tab1:hover {
    background-color: rgba(151, 151, 151, 0.637);

  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 200px;
    border:none;
    box-shadow: 0px 5px rgba(104, 75, 37, 0.938);
    border-radius: 10px;
    background-color: rgba(167, 121, 61, 0.938);
    color: white;
    font-size: 20px;
     font-weight: 500;
    margin-bottom: 10%;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2Dis {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 200px;
    border:none;
    box-shadow: 0px 5px rgba(151, 124, 88, 0.938);
    border-radius: 10px;
    background-color: rgba(165, 134, 93, 0.938);
    color: rgb(173, 173, 173);
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10%;
    cursor: no-drop;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mM {
    width: 250px;
    height: 41px;
    cursor: pointer;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.055) 0%, rgba(0,0,0,0.366469268371411) 63%, rgba(0,0,0,0.7782339742537641) 89%), url(/static/media/bg.4c1d4245.jpg) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
   }

  .allwrap{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .clientZone {
    flex-flow: row wrap;
    margin-left: 2%;
    padding-top: 2%;

  }

  .clientZoneMain {
    display: flex;
    margin-bottom: 5%;
    padding-top: 2%;
    justify-content: space-between;
    width: 600px;

  }

  .nftPic {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .clientStory {
    font-size: 12px;
    color: black;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 400px;
    margin-left: 2%;
    margin-right: 3%;
    border-radius: 10px;
    text-align: justify;

  }

  .nftImg {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .picAndStory {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 1%;
  }

  .writer {
    width: 30px;
    height: 30px;
  }

  .picAndName {
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 187, 0);
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100px;
    border-radius: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .writerDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5PX;
  }

  .clientName {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    color: white;

  }

  .clientName2 {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 60px;
    color: white;

  }

  .ownersSection {
    display: flex;
    flex-flow: row wrap;
    overflow-x: scroll;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .voteArea {
    margin-top: 10px;
  }

  .rabitIcon {
    width: 80px;
    height: 80px;
    cursor: pointer
  }

  .rabitIcon:active {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .rabitIconDiv {

    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
  }

  .submitted {

    text-align: center;
    font-size: large;
    color: white;

  }

  .rabitIconDiv button {


    background-color: rgba(240, 248, 255, 0);

  }

  .rabitIconDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .score {
    font-size: 60px;
  }

  .voteBtn {
    font-size: 25px;
    width: 50px;
    background-color: white;
    cursor: pointer;
    color: black;
    border-radius: 5px;
  }

  .voteBtn:hover {
    background-color: rgb(192, 192, 192);
    color: #5c5c5c;
  }

  .voteBtn:active {
    background-color: #363636;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    color: rgb(255, 255, 255);
  }

  .points {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 25px;
    background-color: #3b3b3b80;
  }

  .countWords {
    color: white;
  }

  .logo {
    width: 250px;
    height: 147px;
  }

  .logoDiv {

    margin-top: 10px;
  }

  .nb {
    color: white;
    font-size: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: 500;
    font-style: italic;
    text-shadow: 4px 3px 0 #41414198;
  }
  
  .headerPicAndTitle {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .closeNote {
    position: absolute;
    right: 30%;
    top: 13%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 35px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
    cursor: pointer;
  }

  .checkedPicDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .checkedPic {
    width: 170px;
    height: 170px;
  }

  .loginFormNote {
    height: 300px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .noteSuccess {
    color: white;
    text-align: center;
    margin-top: 15%;
    font-size: 25px;
  }

  .noteSuccess2 {
    font-size: 15px;
    color: white;
    text-align: center;
  }

    .voteBtn {
    color: white;
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn:hover {
    color: rgba(0, 0, 0, 0.562);
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn2 {
    color: rgba(255, 255, 255, 0.562);
    font-size: 20px;
    text-align: center;

  }

  .voteBtn2:hover {
    color: rgba(179, 179, 179, 0.562);
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }

  .continue {
    background-color: rgba(53, 173, 53, 0);
    border-radius: 7px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .continue:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
  }

  a {
    color: white;
    text-decoration: none;
  }


/* width */
::-webkit-scrollbar {
  width: 10px;
 }

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(88, 87, 82);
  border-radius: 50px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 105, 103);
  border-radius: 50px;
}

.picAndCon{
  display: flex;
  justify-content: center;
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  flex-flow: row nowrap;
}

.imageBar img{
  width: 200px;
  margin-bottom: 4%;
  border: rgb(255, 255, 255) 2px solid;
}

.imageBar{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.contentH{
  color: white;
  font-weight: 500;
  font-size: 30px;
  text-align: left;
  text-decoration: underline;
}

.contentBar p{
  color: black;
  font-weight: 600;
  text-align: left;
}
 

.textField{
  color: black;
  font-weight: 600;
}

.textField2{
  color: black;
  font-weight: 600;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 3%;
}

.description{
  color: white;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 1%;
  padding-left: 2%;
}

.descriptionCon{
  background-color: #5b5434;
  color: white;
  font-weight: 500;
  padding: 10px;
  width: 100%;
  font-size: 18px;
  border-radius: 10px;
  margin-bottom: 2%;
  border: 2px black dashed;
}

.descriptionMain{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.agreeBtn {
  display: flex;
  flex-flow: row nowrap;  
}

.agreeBtn4 {
  display: flex;
  flex-flow: row nowrap;  
  width: 100%;
}

.tic {
  width: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 3%;
}

.agree {
  font-size: 17px;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
}

.agreeBtn2{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.agreeBtn3{
  width: 100%;
  justify-content: flex-end;
  flex-flow: column nowrap;
  display: flex;
  margin-left: 2%;
}

.con2{
  color: white;
  font-weight: 500;
  margin-top: 2%;
  font-size: 18px;
  width: 70%;
  text-align: justify;
}

.con2Main{
  justify-content: space-between;
  flex-flow: row nowrap;
  display: flex;
  width: 100%;
  margin-top: 2%;
}

.approve{
  background-color: rgb(1, 155, 53);
  color: white;
  font-weight: 600;
  border-radius: 15px;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  padding: 10px;
  font-size: 20px;
  box-shadow: 0 5px rgb(1, 119, 40);
  margin-right: 5%;
  cursor: pointer;
  transition: ease-in 0.2px
}

.approveDis{
  background-color: rgb(97, 179, 124);
  color: rgb(177, 177, 177);
  font-weight: 600;
  border-radius: 15px;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  padding: 10px;
  font-size: 20px;
  box-shadow: 0 5px rgb(69, 143, 94);
  margin-right: 5%;
  cursor: no-drop;
  transition: ease-in 0.2px;
}


.approve:hover{
  background-color: rgb(2, 175, 59);
}

.approveDiv{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.wallet2:hover {
  -webkit-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
          animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

.choosebtn{
  color: white;
  font-size: 16px;
}

.ownNFT{
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
  margin-top: 8%;
  margin-bottom: 5.5%;
}


.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  display: none;

}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.formMain {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 80%;
  height: 70vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #32d400;
  z-index: 100000;
  padding: 5%;

}

.formMain2 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 50%;
  height: 35vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #32d400;
  z-index: 100000;
  padding: 5%;

}

.formMain1 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #131313b0;
  position: fixed;
  width: 100%; 
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

}

.closeNote2 {
  position: fixed;
  right: 0;
  top: 5%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
 }

.closeNote2:hover,
.closeNote2:focus {
  color: rgb(71, 70, 70);
}

.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  display: none;

}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.formCon{
  color: white;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
}

.wallet2New{
  background-color: #5b5434;
  color: white;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 25px;
  margin-bottom: 2%;
  padding: 5px;
  border: white 2px solid;
  cursor: pointer;
}

.tokenIds {
  display: flex;
  justify-content: center;
  color: white;
  flex-flow: row wrap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 10%;
}

.images_show {
  width: 90%;

}


.ownNFT {
  margin-top: 3%;
  margin-bottom: 12%;
}


.flip-card {
  background-color: transparent;
  width: 150px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  background-color: #e0706c;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  margin-top: 10%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.btnCenter {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.flip-card-front {
  /* background-color: #bbb;*/
  color: black;
}

.flip-card-back {
  /*background-color: #2980b9;*/
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.contentColumn{
  flex-flow: column nowrap;
   
}

.formCon2{
  color: rgb(209, 209, 209);
  font-size: 16px;
  text-align: center;
}

.mintBtn{
  background-color: green;
  font-size: 20px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  padding: 10px;
  border: white solid 2px;
  margin-top: 5%;
  cursor: pointer;
}

.errorMessage {

  font-size: 18px;
  color: #fc534d;
  text-align: center;
  margin-top: 3%;

}

.loadingContainer {}

.loadingText {
  font-size: 22px;
  text-align: center;
  color: rgb(204, 204, 204);
  margin-top: 3%;
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.successfully {
  text-align: center;
  color: rgb(124, 199, 74);
  font-size: 22px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.arrow{
  width: 30px;
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-left: 4%;
  cursor: pointer;
  -webkit-animation: zoominoutsinglefeatured 5s infinite;
          animation: zoominoutsinglefeatured 5s infinite;
}

@-webkit-keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

.arrowAndCon{
  display: flex;
  margin-top: 3%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

}

.instructions{
  color: white;
  text-align: center;
  margin-top: 10px;
}

.formCon2{
  color: rgb(212, 211, 211);
  text-align: center;
  margin-top: 10px;
}
}

@media screen and (min-width: 1920px) {

  .upload{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .form {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  #block_container{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .main {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    padding-bottom: 2%;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1000;
    bottom: 0;
    background-color: black;
  }

  .form__input {
    font-size: 25px;
    width: 30%;
    border: none;
    border-radius: 10px;
    padding: 10px;
  }

  .comment {
    font-size: 20px;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px;
  }

  .button {
    background-color: rgb(53, 173, 53);
    border-radius: 10px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    cursor: pointer;
    margin-top: 10px;
   }

  .button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .userPic {
    width: 45px;
    height: 45px;
    padding-right: 10px;
  }

  .user {
    display: flex;
    flex-flow: row nowrap;
  }

  .line {
    width: 100%;
    height: 5px;
    background-color: rgb(255, 196, 0);
    margin-bottom: 2%;
  }

  .tab1 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: black;

  }

  .centerMM {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;    
  }

  .tab1:hover {
    background-color: rgba(151, 151, 151, 0.637);

  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 230px;
    border:none;
    box-shadow: 0px 5px rgba(104, 75, 37, 0.938);
    border-radius: 10px;
    background-color: rgba(167, 121, 61, 0.938);
    color: white;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;    font-weight: 500;
    margin-bottom: 10%;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2Dis {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 230px;
    border:none;
    box-shadow: 0px 5px rgba(151, 124, 88, 0.938);
    border-radius: 10px;
    background-color: rgba(165, 134, 93, 0.938);
    color: rgb(173, 173, 173);
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;    font-weight: 500;
    margin-bottom: 10%;
    cursor: no-drop;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mM {
    width: 250px;
    height: 41px;
    cursor: pointer;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.055) 0%, rgba(0,0,0,0.366469268371411) 63%, rgba(0,0,0,0.7782339742537641) 89%), url(/static/media/bg.4c1d4245.jpg) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
   }

  .allwrap{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .clientZone {
    flex-flow: row wrap;
    margin-left: 2%;
    padding-top: 2%;

  }

  .clientZoneMain {
    display: flex;
    margin-bottom: 5%;
    padding-top: 2%;
    justify-content: space-between;
    width: 600px;

  }

  .nftPic {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .clientStory {
    font-size: 12px;
    color: black;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 400px;
    margin-left: 2%;
    margin-right: 3%;
    border-radius: 10px;
    text-align: justify;

  }

  .nftImg {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .picAndStory {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 1%;
  }

  .writer {
    width: 30px;
    height: 30px;
  }

  .picAndName {
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 187, 0);
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100px;
    border-radius: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .writerDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5PX;
  }

  .clientName {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    color: white;

  }

  .clientName2 {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 60px;
    color: white;

  }

  .ownersSection {
    display: flex;
    flex-flow: row wrap;
    overflow-x: scroll;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .voteArea {
    margin-top: 10px;
  }

  .rabitIcon {
    width: 80px;
    height: 80px;
    cursor: pointer
  }

  .rabitIcon:active {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .rabitIconDiv {

    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
  }

  .submitted {

    text-align: center;
    font-size: large;
    color: white;

  }

  .rabitIconDiv button {


    background-color: rgba(240, 248, 255, 0);

  }

  .rabitIconDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .score {
    font-size: 60px;
  }

  .voteBtn {
    font-size: 25px;
    width: 50px;
    background-color: white;
    cursor: pointer;
    color: black;
    border-radius: 5px;
  }

  .voteBtn:hover {
    background-color: rgb(192, 192, 192);
    color: #5c5c5c;
  }

  .voteBtn:active {
    background-color: #363636;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    color: rgb(255, 255, 255);
  }

  .points {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 25px;
    background-color: #3b3b3b80;
  }

  .countWords {
    color: white;
  }

  .logo {
    width: 250px;
    height: 147px;
  }

  .logoDiv {

    margin-top: 10px;
  }

  .nb {
    color: white;
    font-size: 40px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: 500;
    font-style: italic;
    text-shadow: 4px 3px 0 #41414198;
  }
  
  .headerPicAndTitle {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .closeNote {
    position: absolute;
    right: 30%;
    top: 13%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 35px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
    cursor: pointer;
  }

  .checkedPicDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .checkedPic {
    width: 170px;
    height: 170px;
  }

  .loginFormNote {
    height: 300px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .noteSuccess {
    color: white;
    text-align: center;
    margin-top: 15%;
    font-size: 25px;
  }

  .noteSuccess2 {
    font-size: 15px;
    color: white;
    text-align: center;
  }

    .voteBtn {
    color: white;
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn:hover {
    color: rgba(0, 0, 0, 0.562);
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn2 {
    color: rgba(255, 255, 255, 0.562);
    font-size: 20px;
    text-align: center;

  }

  .voteBtn2:hover {
    color: rgba(179, 179, 179, 0.562);
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }

  .continue {
    background-color: rgba(53, 173, 53, 0);
    border-radius: 7px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .continue:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
  }

  a {
    color: white;
    text-decoration: none;
  }


/* width */
::-webkit-scrollbar {
  width: 10px;
 }

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(88, 87, 82);
  border-radius: 50px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 105, 103);
  border-radius: 50px;
}

.picAndCon{
  display: flex;
  justify-content: center;
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  flex-flow: row nowrap;
}

.imageBar img{
  width: 200px;
  margin-bottom: 4%;
  border: rgb(255, 255, 255) 2px solid;
}

.imageBar{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.contentH{
  color: white;
  font-weight: 500;
  font-size: 37px;
  text-align: left;
  text-decoration: underline;
}

.contentBar p{
  color: black;
  font-weight: 600;
  text-align: left;
}

.textField{
  color: black;
  font-weight: 600;
  font-size: 22px;
}

.textField2{
  color: black;
  font-weight: 600;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 3%;
}

.description{
  color: white;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 1%;
  padding-left: 2%;
}

.descriptionCon{
  background-color: #5b5434;
  color: white;
  font-weight: 500;
  padding: 10px;
  width: 80%;
  font-size: 22px;
  border-radius: 10px;
  margin-bottom: 2%;
  border: 3.5px black dashed;
}

.descriptionMain{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.agreeBtn {
  display: flex;
  flex-flow: row nowrap;  
}

.agreeBtn4 {
  display: flex;
  flex-flow: row nowrap;  
  width: 100%;
}

.tic {
  width: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 3%;
}

.agree {
  font-size: 22px;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
}

.agreeBtn2{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.agreeBtn3{
  width: 100%;
  justify-content: flex-end;
  flex-flow: column nowrap;
  display: flex;
  margin-left: 2%;
}

.con2{
  color: white;
  font-weight: 500;
  margin-top: 2%;
  font-size: 23px;
  width: 70%;
  text-align: justify;
}

.con2Main{
  justify-content: space-between;
  flex-flow: row nowrap;
  display: flex;
  width: 100%;
  margin-top: 2%;
}

.approve{
  background-color: rgb(1, 155, 53);
  color: white;
  font-weight: 600;
  border-radius: 15px;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  padding: 10px;
  font-size: 24px;
  box-shadow: 0 5px rgb(1, 119, 40);
  margin-right: 5%;
  cursor: pointer;
  transition: ease-in 0.2px;
  width: 150px;

}

.approveDis{
  background-color: rgb(97, 179, 124);
  color: rgb(177, 177, 177);
  font-weight: 600;
  border-radius: 15px;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  padding: 10px;
  font-size: 24px;
  box-shadow: 0 5px rgb(69, 143, 94);
  margin-right: 5%;
  cursor: no-drop;
  transition: ease-in 0.2px;
  width: 150px;
}


.approve:hover{
  background-color: rgb(2, 175, 59);
}

.approveDiv{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.wallet2:hover {
  -webkit-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
          animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

.choosebtn{
  color: white;
  font-size: 20px;
}

.ownNFT{
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
  margin-top: 8%;
  margin-bottom: 5.5%;
}


.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  display: none;

}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.formMain {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 80%;
  height: 70vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #32d400;
  z-index: 100000;
  padding: 5%;

}

.formMain2 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 40%;
  height: 25vh;
  top: 50%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #32d400;
  z-index: 100000;
  padding: 5%;

}

.formMain1 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #131313b0;
  position: fixed;
  width: 100%; 
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

}

.closeNote2 {
  position: fixed;
  right: 0;
  top: 5%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
 }

.closeNote2:hover,
.closeNote2:focus {
  color: rgb(71, 70, 70);
}

.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  display: none;

}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.formCon{
  color: white;
  text-align: center;
  font-size: 50px;
  font-weight: 500;
}

.wallet2New{
  background-color: #5b5434;
  color: white;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 25px;
  margin-bottom: 2%;
  padding: 5px;
  border: white 2px solid;
  cursor: pointer;
}

.tokenIds {
  display: flex;
  justify-content: center;
  color: white;
  flex-flow: row wrap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 10%;
}

.images_show {
  width: 100%;

}


.ownNFT {
  margin-top: 3%;
  margin-bottom: 9%;
}


.flip-card {
  background-color: transparent;
  width: 150px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  background-color: #e0706c;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  margin-top: 10%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.btnCenter {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.flip-card-front {
  /* background-color: #bbb;*/
  color: black;
}

.flip-card-back {
  /*background-color: #2980b9;*/
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.contentColumn{
  flex-flow: column nowrap;
   
}

.formCon2{
  color: rgb(209, 209, 209);
  font-size: 20px;
  text-align: center;
}

.mintBtn{
  background-color: green;
  font-size: 35px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  padding: 10px;
  border: white solid 2px;
  margin-top: 5%;
  cursor: pointer;
}

.errorMessage {

  font-size: 25px;
  color: #fc534d;
  text-align: center;
  margin-top: 3%;

}

.loadingContainer {}

.loadingText {
  font-size: 30px;
  text-align: center;
  color: rgb(204, 204, 204);
  margin-top: 3%;
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.successfully {
  text-align: center;
  color: rgb(124, 199, 74);
  font-size: 25px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.arrow{
  width: 30px;
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-left: 4%;
  cursor: pointer;
  -webkit-animation: zoominoutsinglefeatured 5s infinite;
          animation: zoominoutsinglefeatured 5s infinite;
}

@-webkit-keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
  }
}

.arrowAndCon{
  display: flex;
  margin-top: 3%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

}

.instructions{
  color: white;
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
}

.formCon2{
  color: rgb(212, 211, 211);
  text-align: center;
  margin-top: 10px;
}
}
